export const environment = {
  production: false,
  api_base_url: 'https://api-recette-po-tv.francetvpub.fr',
  manager_api_base_url: 'https://api-recette-po-manager.francetvpub.fr',
  contract_microservice_api_base_url: 'https://tv-contract-recette-po-api.francetvpub.fr',
  oidc: {
    client_id: '701CFFA8872F2D40',
    scope: 'openid email profile groups DistinguishedName',
    response_type: 'code',
    grant_type: 'authorization_code',
    auth_callback_path: '/login/callback',
    openid_authorize_endpoint: 'https://portailacces-rct.francetvpub.fr/IdPOAuth2/authorize/ilexoidc',
  },
  pop_dom_microservice_base_url: "https://popdom-recette-po-api.francetvpub.fr",
};
